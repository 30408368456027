import { Ref } from "vue";

export interface SortProps {
  prop: string;
  order: "ascending" | "descending";
  method?: ((a, b) => number) | null;
}

export const sortFn = (sortProps: Ref<SortProps>) => (a, b) => {
  if (sortProps.value.method) {
    return sortProps.value.order === "descending"
      ? sortProps.value.method(b, a)
      : sortProps.value.method(a, b);
  }

  const aValue = sortProps.value.prop.split(".").reduce((x, y) => x[y], a);
  const bValue = sortProps.value.prop.split(".").reduce((x, y) => x[y], b);
  if (aValue < bValue) {
    return sortProps.value.order === "descending" ? 1 : -1;
  }
  if (aValue > bValue) {
    return sortProps.value.order === "descending" ? -1 : 1;
  }

  return 0;
};
